$(document).ready(
    function () {

        const myModal = new bootstrap.Modal('#videoModal', {
            keyboard: false
        })

        $(document).on("click", "#gift-image-play-button", function () {
            const modalToggle = document.getElementById('videoModal');
            myModal.show(modalToggle);

        });

    });


